<template>
  <div
    class="text-center"
    style="background-color: #ffffff"
  >
    <div 
      style="display: none; height: 50px; margin-bottom: 10px"
      :style="{ backgroundColor: sitePub.background, color: sitePub.fontColor }"
    />
    <div class="hero-bg" />

    <v-container
      fluid
      style="background-color: #f4f4f4;"
    >
      <v-row
        class="mb-4"
        justify="start"
      >
        <v-col
          cols="auto"
          class="text-left text-caption text-sm-subtitle-1"
          style="cursor: pointer"
          @click="$router.back()"
        >
          <v-icon>
            mdi-arrow-left-bold-box
          </v-icon>
        </v-col>
        <v-col
          cols="auto"
          class="text-left text-caption text-sm-subtitle-1"
        >
          Breadcrumbs / Catagory / Location / Store / ...
        </v-col>
      </v-row>
          
      <v-row
        no-gutters
        justify="center"
        style="min-height: 100vh;"
      >      
        <v-col
          cols="auto"
        >
          <!-- MAIN CARD -->
          <v-card
            class="justify-center"
            min-width="300px"
            :width="$vuetify.breakpoint.mobile ? '' : '600px' "
            max-width="600px"
          >
            <!-- TOP STORE NAME BOX -->
            <v-row
              class="d-md-none"
              justify="center"
              no-gutters
              style="padding: 8px"
            >
              <v-col
                cols="6"
                style="background-color: #fff"
              >
                <v-img
                  v-if="sitePub.logo"
                  alt="Logo"
                  contain
                  :src="sitePub.logo || '@/assets/tcc-logo.png'"
                  transition="scale-transition"
                  height="50"
                />
              </v-col>
              <v-col
                cols="6"
                style="background-color: #fff"
              >
                {{ sitePub.name }}
                <div class="text-caption text-truncate">
                  <a
                    :href="gmaps()"
                    target="_blank"
                    style="text-decoration: none; color: #333"
                  >
                    <v-icon>mdi-map-marker</v-icon><span>{{ sitePub.address }}</span>
                  </a>
                </div>
                <div
                  v-if="km"
                  class="text-caption"
                >
                  {{ km }}
                </div>
              </v-col>  
            </v-row>
            <!-- CAROUSEL -->

            <v-carousel 
              :v-model="picIndex"
              height="500px"
              show-arrows-on-hover
              color="white"
            >
              <v-carousel-item
                v-for="(obj,i) in filterImages()"
                :key="obj.src"       
                :src="obj.src"
                @click.stop="showViewer(i)"
              />
              <span
                v-if="discnt"
                class="discount"
              ><v-icon
                color="grey darken-3"
              >mdi-arrow-down-bold</v-icon>{{ discnt }}</span>
            </v-carousel>

            <v-row
              dense
              no-gutters
            >
              <v-col cols="2">
                <v-icon
                  style="display: absolute; top: -80px; left: 0px; z-index: 1000;"
                  color="white"
                  @click.stop="showViewer(picIndex)"
                >
                  mdi-magnify-plus
                </v-icon>

                <v-icon>mdi-eye</v-icon> {{ post.views }}
              </v-col>
              <v-col
                cols="1"
                sm="3"
              />
              <v-col><v-icon>mdi-share-variant</v-icon> Share</v-col>
              <v-col><v-icon>mdi-heart-outline</v-icon> Save</v-col>
              <v-col>
                <v-icon color="neutral">
                  mdi-flag-variant-outline
                </v-icon> Report
              </v-col>
            </v-row>
            <v-card-title class="title-limit text-truncate">
              {{ post.title }}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <tcc-price
                    :was="post.was" 
                    :now="post.now"
                    lg
                  />
                </v-col>
                <v-col class="text-right text-sm-body-1">
                  <ul>
                    <li>Posted: {{ post.pub | ago }} ago</li>
                    <li>{{ post.q }} left</li>
                    <li>{{ post.reason }}</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="text-left text-sm-body-1 pb-8 font-weight-medium"
                  style="white-space: pre-wrap;"
                >
                  {{ post.desc }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- BOTTOM CARD -->
          <v-card             
            class="justify-center d-md-none mt-4"
            min-width="300px"
            width="600px"
          > 
            <v-card-text>
              <v-row
                no-gutters
                justify="center"
              >
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <p
                    v-if="sitePub.tel"
                    class="font-weight-medium mt-4"
                  >
                    Tel: {{ sitePub.tel }}
                  </p>
                  <div>
                    <table
                      v-if="sitePub.hours"
                      style="width: 100%; text-align: left; margin-top: 20px "
                    >
                      <thead class="font-weight-medium">
                        Hours
                      </thead>
                  
                      <tr
                        v-for="(h,d) in hours()"
                        :key="d"
                      >
                        <td>{{ d }}</td><td>{{ h }}</td>
                      </tr>
                    </table>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="d-flex align-center"
                >
                  <tcc-button
                    class="my-12 mx-auto"
                    text="CHAT TO STORE"
                    width="80%"
                    height="50px"
                  />
                </v-col>  
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>


        <!-- SIDE BOX -->
        <v-col
          class="d-none d-md-flex align-start px-4"
          cols="4"
        >
          <v-card
            
            max-width="400px"
            min-width="300px"
          >
            <v-card-title>
              <v-img
                v-if="sitePub.logo"
                alt="Logo"

                contain
                :src="sitePub.logo || '@/assets/tcc-logo.png'"
                transition="scale-transition"
                height="50"
              />
            </v-card-title>
            <v-card-text class="text-left">
              <div class="text-h6">
                {{ sitePub.name }}
              </div>

              <a
                :href="gmaps()"
                target="_blank"
                style="text-decoration: none; color: #333"
              >
                <v-icon>mdi-map-marker</v-icon><span>{{ sitePub.address }}</span>
              </a>
              
              <div
                v-if="km"
                style="margin-left: 24px"
              >
                {{ km }}
              </div>
              <p
                v-if="sitePub.tel"
                class="font-weight-medium mt-4"
              >
                Tel: 12345678
              </p>
              <div>
                <table
                  v-if="sitePub.hours"
                  style="width: 100%; text-align: left; margin-top: 20px "
                >
                  <thead class="font-weight-medium">
                    Hours
                  </thead>
                  
                  <tr
                    v-for="(h,d) in hours()"
                    :key="d"
                  >
                    <td>{{ d }}</td><td>{{ h }}</td>
                  </tr>
                </table>
              </div>

              <tcc-button
                class="my-12 mx-auto"
                text="CHAT TO STORE"
                width="80%"
                height="50px"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div
      class="hero-bg"
      style="margin-bottom: 0"
    />
    <tcc-foot style="margin-top: 100px" />
  </div>
</template>

<script>

import 'viewerjs/dist/viewer.css';
// import { component as VueViewer } from 'v-viewer';
import { api as viewerApi } from "v-viewer";
// import tccEdge from "@/components/tccEdge.vue";
import tccFoot from "@/components/tccFooter.vue";
import tccPrice from "@/components/tccPrice.vue";
import tccButton from "@/components/tccButton.vue";

// import Vue from 'vue';
// Vue.use( VueViewer );

export default {
    name: 'Post',
    components: {
        tccFoot,
        tccPrice,
        tccButton
    },
    data() {
        return {
            sitePub: {},
            post: {},
            picIndex: 0,
            km: "",
            discnt: false
        };
    },
    computed: {
    },
    async mounted(){
        console.log( "Post MOUNTED" );
        
        this.$router.replace( '/' );
        
        console.log(  this.$store.START_PATH, this.$store.START_HASH  );

        // TODO FOR BETTER PERF I WILL ADD CACHE in $store if we nav here from page in app so we dont make more http calls
        // but this call is logged as a "view" so we need to ping that too.
        let postId = this.$route.params.id;
        if( postId.length != 24 )return this.$router.push( '/error?msg=Invalid ID' ); //ERROR PAGE?

        let res = await getPost( postId, this );
        //ERROR 
        if( !res.ok )return this.$router.push( '/error?msg=404 Clearance Not Found' );
        this.post = res.data[0];
        let km = this.$http.getKm( this.post.geo );
        if( km ) this.km = "±" + km + "km from you"; 
        this.discnt = this.discount();

        let data = await getSitePub( this ); //THIS should check $store cache so we dont make extra http calls
        if( data === false )return this.$router.push( '/' ); //ERROR PAGE?
        if( data )this.sitePub = data;
        
        document.title = "TCC - " + this.sitePub.name + ' - ' + this.post.title;
         
    },
    methods:{
        filterImages(){

            if( !this.post || !this.post.images )return [];
            let out = this.post.images.filter( url => !!url );

            let oo = [];
            for( let i of out )oo.push( {
                src: i + '-/resize/600x/',
                'full': i
            } );
            return oo;
        },
        showViewer( i ){

            viewerApi( {
                options: {
                    initialViewIndex: i,
                    url: "full",
                    minZoomRatio: 0.1,
                    title: false,
                    moveable: false,
                    toolbar: false
                },
                images: this.filterImages()
            } );
        },
        discount(){
            if( !this.post.was || !this.post.now )return "";

            let was = parseFloat( this.post.was.substring( 1 ) );
            let now = parseFloat( this.post.now.substring( 1 ) );
            let p = parseInt( ( ( was-now )/was )*100 );
            if( !p || isNaN( p ) )return false;
            return p + "%";
        },
        hours(){
            let days = [ "Mon","Tue","Wed","Thu","Fri","Sat","Sun" ];
            let out = {};
            for( let i in this.sitePub.hours ){
                out[days[i]] = this.sitePub.hours[i];
            }
            return out;
        },
        gmaps(){
            return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent( this.sitePub.name );
        }
    }
};

async function getSitePub( vm ){

    // let orgSite =  getOrgSite(); 
    // if( !orgSite )return {};
    if( !vm.post )return {};
    let orgSite = vm.post.org + '.' + vm.post.site;
    if( orgSite && vm.$store.siteCache && vm.$store.siteCache[orgSite] )return vm.$store.siteCache[orgSite];
    console.log( "NO siteCache" );
    let res =  await vm.$http.getJSON( 'sites', { storePosts: orgSite } );
    if( res.ok ){
        if( vm.$store.siteCache )vm.$store.siteCache[orgSite] = res.data.site; //cache it for future
        return res.data.site;
    }
    return false;
}
// function getOrgSite(){

//     let orgSite = window.location.pathname.split( '/' )[1]; //org.site
//     let os = orgSite.split( '.' );
//     if( os.length != 2 || !os[0] || !os[1] )return null;
//     return orgSite;
// }

async function getPost( postId, vm ){

    if( vm.$store.postCache && vm.$store.postCache[postId] )return vm.$store.postCache[postId];

    let q = {
        _id: { $oid: postId }
    };
    return await vm.$http.postJSON( 'posts', { q, views: true } );

}

// function findPost( postId, posts ){

//     for( let i in posts ){
//         if( posts[i]._id == postId )return posts[i];
//     }
//     return {};
// }



</script>

<style scoped>
.v-image__image--cover {
  background-size: 100% !important;
}
i.v-icon {
    color: var(--v-tccIcon-base) ;
}
.row {
  /* border: 1px solid blue !important; */
}
.col {
  /* border: 1px solid red !important; */
}

.hero-bg {
  width: 100%;
  margin-bottom: -10vw;
  height: 20vw; 
  background: url( '/img/tcc-background.png' ) repeat fixed top center;
  /* background-size: cover; */
}

  .discount {
    position: absolute;
    top: 8px;
    left: 8px;
    font-weight: bolder;
    background-color: #fed402;
      background: linear-gradient(8deg, rgba(240,190,58,1) 0%, rgba(255,215,0,1) 100%, rgba(255,255,255,1) 100%);
    color: #333;
    padding: 4px  8px;
    border-radius: 4px;
      /* transform: rotate(-5deg); */
    /* border: 2px solid #333; */
  }

ul, li {
  list-style-type: none;
  line-height: 150%;
}

.tccBut {
    display: inline-block;
    padding: 2px 4px;
}
.tccBut:hover {
    background-color: #fed402;
    border: 1px solid #333;
    transform: rotate(-5deg);
}
</style>
