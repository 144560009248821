<template>
  <div
    style="position: relative; display: inline-block"
    class="base"
    :class="{ lgDiv: lg }"
  >
    <span
      class="was"
      :class="{lg: lg}"
    >{{ was }}</span>
    <span
      class="now"
      :class="{lg: lg}"
    >{{ now }}</span>
  </div>
</template>

<script>
export default {
    name: 'TccPrice',
    components: {},
    props: {
        was: String,
        now: String,
        lg: Boolean
    },
    data() {
        return {
        };
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
  .was {
    min-width: 85px;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 18px;
    text-decoration: line-through;
    background-color: #3898ec;
    color: #fff;
    padding: 2px 16px;
  }
  .now {
    min-width: 85px;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 4px;
    font-size: 18px;
    font-weight: bolder;
    /* background-color: #fed402; */
    color: #333;
    padding: 2px 16px;
    transform: rotate(-8deg);
    background: rgb(240,190,58);
    background: linear-gradient(8deg, rgba(240,190,58,1) 0%, rgba(255,215,0,1) 100%, rgba(255,255,255,1) 100%);
    /* border: 2px solid #333; */
  }
  .lg {
    padding: 8px 20px;
    font-size: 24px;
  }
  .lg.now {
    top: 30px;
  }
  .lgDiv {
    height: 70px;
    width: 120px;
    /* border: 1px solid red; */
  }

</style>